import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
	name: 'app',
	initialState: {
		loading: true,
		mobileNav: false
	},
	reducers: {
		toggleLoading: state => {
			state.loading = !state.loading;
		},
		toggleMobileNav: state => {
			state.mobileNav = !state.mobileNav;
		}
	}
});

export const { toggleLoading, toggleMobileNav } = appSlice.actions;

export default appSlice.reducer;